import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useComponents } from "../../components"
import { enterAnimation, exitAnimation } from "./animation"

function SalePage({
  styles,
  tips,
  onClick,
  isVisible,
  onAnimationDone,
  selectTip,
  activeTip
}) {
  const Elements = useComponents()

  return (
    <AnimatePresence onExitComplete={onAnimationDone}>
      {isVisible && (
        <div className="formkit-card-inner">
          <motion.div
            key="img-container"
            className="formkit-image-container"
            initial={{ opacity: 0 }}
            animate={enterAnimation}
            exit={exitAnimation}
          >
            <Elements.Image
              className="formkit-image"
              name="image"
              size={{ h: 400, w: 200 }}
              defaults={{
                src:
                  "https://cdn.convertkit.com/assets/images/commerce/product-placeholder.png"
              }}
            />
          </motion.div>
          <motion.div
            key="main-content"
            className="formkit-main"
            initial={{ opacity: 0 }}
            animate={enterAnimation}
            exit={exitAnimation}
          >
            <header>
              <Elements.Heading
                className="formkit-heading-primary font-display"
                name="product_title"
                defaults={{
                  content: "My Tip Jar"
                }}
              />
            </header>
            <div role="main">
              <Elements.Content
                className="formkit-content"
                name="content"
                defaults={{
                  content:
                    "<p>Pellentesque ut euismod sem. Etiam iaculis varius metus vel sodales. Fusce venenatis, elit in convallis feugiat, risus augue tempus urna, ut sodales tortor metus posuere ante. Curabitur vitae enim dignissim, vehicula odio a, maximus felis.</p>"
                }}
              />

              <div className="formkit-divider" />

              <Elements.TipjarButtons
                style={styles.forElement("tip_button")}
                className="formkit-tip-amount"
                activeTip={activeTip}
                activeTipBorderColor={
                  styles.forElement("purchase_button").backgroundColor
                }
                textColor={styles.forElement("content").color}
                selectTip={selectTip}
                tips={tips}
              />

              <motion.div
                whileHover={{ scale: 1.025 }}
                whileTap={{ scale: 0.95 }}
              >
                <Elements.Button
                  className="formkit-purchase-button"
                  name="purchase_button"
                  onClick={onClick}
                  defaults={{
                    content: "Send a tip"
                  }}
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

export default SalePage
