import { useState, useEffect } from "react"
import useFetch from "../../utils/use-fetch"
import user from "../../utils/user"
import { loadStripe } from "@stripe/stripe-js"

function usePayment(context) {
  const [processing, setProcessing] = useState(false)
  const [stripe, setStripe] = useState(null)
  const [payment, setPayment] = useState(null)
  const [errors, setErrors] = useState(null)
  // from local landing-page server, context is product_id, not tipjar_id
  const [request] = useFetch(
    `https://${context.domain}/commerce/tipjars/${context.product_id}/`
  )

  useEffect(() => {
    const load = async () => {
      const result = await loadStripe(context.stripe_key)
      setStripe(result)
    }

    load()
  }, [context.stripe_key])

  return [
    processing,
    payment,
    errors,
    stripe,
    onBuy(request, stripe, setErrors, setPayment, setProcessing)
  ]
}

// price in this use-payment is the tip object, passed from checkout
function onBuy(request, stripe, setErrors, setPayment, setProcessing) {
  return async function ({
    card,
    name,
    email_address,
    price,
    address_country,
    skip_email_validation
  }) {
    setErrors(null)
    setProcessing(true)
    const result = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        name
      }
    })

    if (result.error) {
      setErrors({
        messages: result.error.message
      })
      setProcessing(false)
    } else {
      try {
        /* eslint-disable-next-line */
        const data = {
          pricing_id: price.id,
          amount: price.amount,
          payment_method: result.paymentMethod.id,
          trace_id: user.id(),
          email_address,
          name,
          address_country,
          skip_email_validation
        }

        const response = await request.post({
          path: "tips",
          data: data
        })

        const authResponse = await handlePaymentAuth(
          request,
          stripe,
          price,
          response.tip
        )

        if (authResponse.error) {
          setProcessing(false)

          setErrors({
            messages: [authResponse.error]
          })

          return
        }

        setProcessing(false)

        setPayment({
          cardBrand: result.paymentMethod.card.brand,
          last4: result.paymentMethod.card.last4,
          ...authResponse.tip
        })
      } catch (error) {
        setProcessing(false)
        try {
          const json = await error.response.json()
          setErrors(json.errors)
        } catch {
          setErrors({
            messages: ["Couldn't complete your tip."]
          })
        }
      }
    }
  }
}

function isAuthenticationRequired(authentication) {
  return authentication && authentication.required
}

// Handles payment authentication, if required.
// Returns an object containing the tip or an error.
async function handlePaymentAuth(request, stripe, price, tip) {
  if (isAuthenticationRequired(tip.authentication)) {
    return await authenticatePayment(request, stripe, price, tip)
  }

  return { tip: tip }
}

// Shows the Stripe-supplied 3DS authentication dialog.
// Returns an object containing the tip or an error.
async function authenticatePayment(request, stripe, price, tip) {
  const authentication = tip.authentication

  const response = await stripe.confirmCardPayment(authentication.client_secret)

  if (response.error) {
    return { error: response.error.message }
  } else if (response.paymentIntent.status != "succeeded") {
    return {
      error: `Could not complete payment: ${response.paymentIntent.status}`
    }
  } else {
    const data = {
      complete_3ds: true,
      customer_id: authentication.customer_id,
      stripe_invoice_id: authentication.stripe_invoice_id,
      pricing_id: price.id,
      amount: price.amount
    }

    // If there's an error here, it'll bubble up and be handled by the caller
    const response = await request.post({
      path: "tips",
      data: data
    })

    return { tip: response.tip }
  }
}

export default usePayment
