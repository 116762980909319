import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { enterDelayedAnimation, exitAnimation } from "./animation"
import { useComponents } from "../../components"

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.message
    }
  }

  render() {
    const Elements = useComponents() // Safe to use here because it's not a real hook.

    if (this.state.hasError) {
      return (
        <AnimatePresence>
          <motion.div
            className="formkit-card-inner--fulfillment"
            key="error-card-inner"
            exit={exitAnimation}
          >
            <motion.div
              key="main-content-error"
              initial={{ opacity: 0 }}
              animate={enterDelayedAnimation}
              exit={exitAnimation}
            >
              <header className="formkit-confirmation-header">
                <Elements.Heading
                  className="formkit-heading-primary font-display" // if fulfillment is a red header, should this one match?
                  name="confirmation_title"
                  defaults={{
                    content: "Oops!"
                  }}
                />
              </header>
              <div className="formkit-main" role="main">
                <p>{this.state.errorMessage}</p>
              </div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )
    }

    return <>{this.props.children}</>
  }
}

export default ErrorBoundary
