import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { useComponents } from "../../components"
import {
  enterAnimation,
  enterDelayedAnimation,
  exitAnimation
} from "./animation"
import Cards from "./cards"
import { formatCheckout } from "../../utils/price-functions"

function Fulfillment({ purchase, isVisible, onAnimationDone }) {
  const Elements = useComponents()

  return (
    <AnimatePresence onExitComplete={onAnimationDone}>
      {isVisible && (
        <motion.div
          className="formkit-card-inner--fulfillment"
          key="fulfillment-card-inner"
          animate={enterAnimation}
          exit={exitAnimation}
        >
          <Cards />
          <motion.div
            key="main-content-fulfillment"
            initial={{ opacity: 0 }}
            animate={enterDelayedAnimation}
            exit={exitAnimation}
          >
            <header className="formkit-confirmation-header">
              <Elements.Heading
                className="formkit-heading-primary font-display" // seva-red instead of blue?
                name="confirmation_title"
                defaults={{
                  content: "Thank You!"
                }}
              />
            </header>
            <div className="formkit-main" role="main">
              <dl className="formkit-purchase-confirmation-details">
                <div>
                  <dt>Amount Paid</dt>
                  <dd>{formatCheckout(purchase.price, purchase.coupon)}</dd>
                </div>
                <div>
                  <dt>Order date</dt>
                  <dd>{purchase.created_at}</dd>
                </div>
                <div>
                  <dt>Payment method</dt>
                  <dd className="formkit-purchase-confirmation-card">
                    <svg className={`svg-card-${purchase.cardBrand}`}>
                      <use xlinkHref={`#${purchase.cardBrand}`} />
                    </svg>
                    {purchase.last4}
                  </dd>
                </div>
              </dl>
              <Elements.Content
                className="formkit-content"
                group="content"
                name="content_fulfillment"
                defaults={{
                  content:
                    "<p>Thank you so much for your tip! Support from people like you helps me continue to do what I do, and it is much appreciated.</p>" //something about an emailed receipt?
                }}
              />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Fulfillment
