import "regenerator-runtime/runtime"
import React, { useState, useEffect } from "react"
import CantBuyPage from "../commerce/cantbuy-page"
import Checkout from "./checkout"
import createTemplate from "../../create-template"
import { DialogOverlay, DialogContent } from "@reach/dialog"
import ErrorBoundary from "./error-boundary"
import Fulfillment from "./fulfillment"
import { motion } from "framer-motion"
import SalePage from "./sale-page"
import { useComponents } from "../../components"
import reportingEvent from "../../utils/reporting-event"

import "@reach/dialog/styles.css"

const postMessage = (message) => {
  window.parent.postMessage(JSON.stringify(message), "*")
}

function Dialog({ children }) {
  const [active, setActive] = useState(false)

  useEffect(() => {
    const handleMessage = (event) => {
      try {
        const message = JSON.parse(event.data)

        switch (message.type) {
          case "show-modal":
            setActive(true)
            return true
        }
      } catch (e) {
        // The message wasn't JSON, so we don't handle it.
      }
    }

    window.addEventListener("message", handleMessage)
    return () => window.removeEventListener("message", handleMessage)
  }, [])

  return (
    <DialogOverlay
      className="commerce-overlay"
      isOpen={active}
      onDismiss={() => {
        setActive(false)
        postMessage({ type: "hide-modal" })
      }}
    >
      <DialogContent
        className="commerce-dialog"
        aria-label="Purchase"
        style={{ overflow: "hidden", position: "relative" }}
        key="dialog"
        initial={{ opacity: 0, y: -15 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
      >
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

function Page({ children }) {
  return <div className="commerce-page">{children}</div>
}

function useSearchParams() {
  const [params] = useState(() => {
    try {
      return Object.fromEntries(new URL(document.location).searchParams)
    } catch (e) {
      return {}
    }
  })
  return params
}

function isComponentVisible(name, page, transition) {
  return name == page && name == transition
}

function defaultTip(tips) {
  return tips[0]
}

function Tipjar({
  styles,
  editing,
  currentView = "buy",
  context = {},
  ...props
}) {
  const params = useSearchParams()

  const [currentPage, setCurrentPage] = useState(currentView || "buy")
  const [currentTransition, setTransition] = useState(currentView || "buy")
  const [purchase, setPurchase] = useState(null)
  /* eslint-disable-next-line */

  const tips = context.tips.plans
  const [selectedTip, setSelectedTip] = useState(defaultTip(tips))

  const Elements = useComponents()

  useEffect(() => {
    if (currentPage == "throwaway" || editing) {
      return
    }

    reportingEvent("view", {
      source_name: currentPage,
      source_type: "landing_page",
      target_type: "commerce_product",
      target_name: context.name,
      target_id: context.product_id,
      tags: ["template-tipjar"]
    })
  }, [currentPage, context, editing])

  useEffect(() => {
    currentView = currentView || "buy"
    if (editing && currentPage !== currentView) {
      setCurrentPage("throwaway")

      setTimeout(() => {
        setTransition("transition")
        setCurrentPage(currentView)
        setTimeout(() => {
          setTransition(currentView)
        }, 275)
      }, 300)
    }
  }, [currentView])

  if (editing && !purchase) {
    setPurchase({
      created_at: new Date().toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric"
      }),
      price: selectedTip,
      cardBrand: "visa",
      last4: 4242
    })
  }

  const Wrapper = params.embed ? Dialog : Page

  const variants = {
    buy: { width: 960, maxWidth: "100%", height: "auto" },
    transition: { width: 500, maxWidth: "100%", height: 400 },
    checkout: { width: 500, maxWidth: "100%", height: "auto" },
    cantbuy: { width: 960, maxWidth: "100%", height: "auto" },
    fulfillment: { width: 500, maxWidth: "100%", height: "auto" },
    error: { width: 960, maxWidth: "100%", height: "auto" }
  }
  const current = variants[currentTransition]

  const toCheckout = () => {
    if (editing) {
      return
    }

    setCurrentPage("throwaway")

    setTimeout(() => {
      setTransition("transition")
      setCurrentPage("checkout")
      setTimeout(() => {
        setTransition("checkout")
      }, 275)
    }, 300)
  }

  const toCantBuy = () => {
    setCurrentPage("throwaway")

    setTimeout(() => {
      setTransition("transition")
      setCurrentPage("cantbuy")
      setTimeout(() => {
        setTransition("cantbuy")
      }, 275)
    }, 300)
  }

  const fulfill = (purchase) => {
    setPurchase({
      created_at: new Date().toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric"
      }),
      price: selectedTip,
      ...purchase
    })

    setCurrentPage("throwaway")

    setTimeout(() => {
      setTransition("transition")
      setCurrentPage("fulfillment")
      setTimeout(() => {
        setTransition("fulfillment")
      }, 300)
    }, 300)
  }

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
      context={context}
    >
      <Wrapper>
        <div
          className="formkit-background"
          style={{
            backgroundColor: styles.forElement("background").backgroundColor
          }}
        />
        <div
          className="formkit-background"
          style={styles.forElement("background")}
        />

        <motion.div
          className={`formkit-card ${
            currentPage ? `formkit-card--${currentPage}` : ""
          }`}
          style={styles.forElement("card_background")}
          initial={{ width: 960, height: 100 }}
          animate={current}
          transition={{
            type: "tween",
            ease: "anticipate",
            duration: 0.3
          }}
          key="main-card"
        >
          <ErrorBoundary>
            <SalePage
              styles={styles}
              tips={tips}
              onClick={context.published ? toCheckout : toCantBuy}
              isVisible={isComponentVisible(
                "buy",
                currentPage,
                currentTransition
              )}
              selectTip={setSelectedTip}
              activeTip={selectedTip}
            />
            <Checkout
              styles={styles}
              tip={selectedTip}
              context={context}
              onPurchase={(purchase) => fulfill(purchase)}
              isVisible={isComponentVisible(
                "checkout",
                currentPage,
                currentTransition
              )}
            />
            <Fulfillment
              styles={styles}
              purchase={purchase}
              isVisible={isComponentVisible(
                "fulfillment",
                currentPage,
                currentTransition
              )}
            />
            <CantBuyPage
              styles={styles}
              isVisible={isComponentVisible(
                "cantbuy",
                currentPage,
                currentTransition
              )}
            />
          </ErrorBoundary>
        </motion.div>

        <footer>
          <Elements.PoweredByConvertKit variant="block" />
        </footer>
      </Wrapper>
    </Elements.LandingPage>
  )
}

export default createTemplate(Tipjar, { name: "Tipjar" })
